<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "แก้ไขข้อมูลงานอื่นๆ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขข้อมูลงานอื่นๆ",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: "งานอื่นๆ",
          active: false,
          href: "/extra-service",
        },
        {
          text: "แก้ไขข้อมูลงานอื่นๆ",
          active: true,
        },
      ],
      extraId: this.$route.params.extraId,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      payment: "",
      type: "",
      roId: "",
      serviceRo: [],
      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      nameTh: "",
      nameEn: "",
      extraCode: "",
      price: "",
      workDuration: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
    };
  },
  validations: {
    nameTh: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/extra-service/show", {
          params: {
            extraId: this.extraId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.extraCode = response.data.data.extraCode;
          this.nameTh = response.data.data.nameTh;
          this.nameEn = response.data.data.nameEn;
          this.price = response.data.data.price;
          this.workDuration = response.data.data.workDuration;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.updateExtraService();
      }
    },
    updateExtraService: function() {
      useNetw
        .put("api/extra-service/update", {
          extraId: this.extraId != null ? this.extraId : undefined,
          extraCode: this.extraCode != null ? this.extraCode : undefined,
          nameTh: this.nameTh,
          nameEn: this.nameEn != null ? this.nameEn : undefined,
          price: this.price != null ? this.price : undefined,
          workDuration: this.workDuration != null ? this.workDuration : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "extra-service",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        รหัสงานอื่นๆ:
                        <b-form-input
                          v-model="extraCode"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ราคา:
                        <b-form-input
                          v-model="price"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชั่วโมงงาน:
                        <b-form-input
                          v-model="workDuration"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
